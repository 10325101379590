import React from 'react';
import imgClose from "../../Assets/img/exit-label.svg"
import { LabelReportFilterStyle } from "./LabelReportFilterStyle";


interface Props {
    selectedReport: any;
    onClose(): void;
}
export const LabelReportFilter = (props: Props) => {

    const { selectedReport, onClose } = props;

    const classes = LabelReportFilterStyle();

    return (
        <div className={classes.container}>
            <p className={classes.title}>{selectedReport.reporte}</p>
            <button className={classes.btnClose} onClick={onClose} >
                <img className={classes.imgBtnClose} src={imgClose} alt="cerrar.jpg" />
            </button>
        </div>
    )
}
