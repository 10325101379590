import { LanguagesProperties } from "../LanguagesProperties";

export const es_PE: LanguagesProperties = {
  wizardFormTitle: "Completá los datos restantes para registrar tu usuario.",
  requiredField: "El campo es requerido",
  invalidMailFormat: "El formato del email no es válido",
  invalidIdentifier: "Identificador inválido",
  min6charsField: "El campo debe tener un mínimo de 6 caracteres",
  alphanumeric: "El campo debe tener al menos una letra y un número",
  passwordsDontMatch: "Las contraseñas deben coincidir",
  requiredCaptcha: "El captcha debe ser validado",
  txtEmail: "Email para Jira",
  txtPassword: "Contraseña",
  txtRepeatPassword: "Repetir contraseña",
  txtIdentification: "RUC",
  txtRegister: "Registrarme",
  txtAccept: "Aceptar",
  txtCompanyName: "Razón Social",
  txtCustomerCode: "Código de Cliente",
  txtAdditionalReports: "Reportes Adicionales",
  btnAdditionalReports: "Reportes Adicionales",
  btnAcceptModalReportsExpress: "Aceptar",
  btnCancelModalReportsExpress: "Cancelar",
  txtSearchReports: "Buscar"
};
