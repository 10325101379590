export const textfieldStyle: any = {
  root: {
    height: 48,
    width: "100%",
    lineHeight: "3.4875em"
  },
  label: {
    "&$focused": {
      color: "#4285f4"
    }
  },
  focused: {},
  button: {
    height: 48,
    width: 163,
    margin: "auto",
    padding: "8px 24px",
    fontSize: 14,
    boxShadow: "none",
    fontWeight: "bold",
    borderRadius: 24,
    display: "block",
    backgroundColor: "#6A00A7 !important",
    textTransform: 'none',
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#6fa2f6"
    }
  },
  iconButton: {
    padding: "8px"
  },
  flatButton: {
    height: 40,
    margin: "auto",
    padding: "8px 24px",
    fontSize: 14,
    boxShadow: "none",
    fontWeight: "bold",
    borderRadius: 24,
    color: "#4285f4",
    "&:hover": {
      backgroundColor: "rgba(76, 144, 245, 0.12)"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "rgba(76, 144, 245, 0.24)"
    }
  }
};
