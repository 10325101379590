import { Languages } from "./Languages";
import { es } from "./lang/es";
import { en } from "./lang/en";
import { es_PE } from "./lang/es_PE";

export class I18N {
  public static currentLocale: string;
  public static currentCountry: string;
  public static currentLanguage: string;

  private constructor() {}

  private static default = "es";
  private static languages: Languages = {
    es: es,
    en: en,
    es_PE: es_PE
  };

  static get(key: string): string {
    if (this.currentLocale) {
      return (this.languages as any)[this.currentLocale][key];
    }

    return (this.findLocale(navigator.language, "-") as any)[key];
  }

  private static findLocale(language: string, splitBy: string): string {
    let splitted: any = language.split(splitBy);

    let lang: string = splitted[0];
    let country: string = splitted[1];

    this.currentCountry = country;
    this.currentLocale = lang + "_" + country;

    if (lang && country && this.languages.hasOwnProperty(this.currentLocale)) {
      this.currentCountry = country;
      this.currentLanguage = lang;
    } else if (this.languages.hasOwnProperty(lang)) {
      this.currentLocale = lang;
      this.currentLanguage = lang;
    } else {
      this.currentLocale = this.default;
      this.currentLanguage = this.default;
    }

    return (this.languages as any)[this.currentLocale];
  }
}
