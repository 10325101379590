import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const LabelReportFilterStyle = makeStyles((theme: Theme) => createStyles({
    title: {
        fontWeight: 500,
        fontSize: 11,
        lineHeight: "9px",
        color: "var(--color-txt-negro)"
    },
    container: {
        background: "#EAEDFD",
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        padding: "0px 8px 0px 11px",
        gap: 5,
        maxWidth: "100%",
        height: 20,
    },
    btnClose: {
        display: "flex",
        background: "none",
        border: "none",
        cursor: "pointer"
    },
    imgBtnClose: {
        height: 7,
        width: 7
    }
}));