import "./ModalRptExpress.css"

import React, { Component } from 'react';
import { Button } from "@material-ui/core";
import { I18N } from "../../i18n/I18N";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField";
import expressReports from '../../Assets/data/expressReports';
import { LabelReportFilter } from '../LabelReportFilter/LabelReportFilter';
import exitLabel from "../../Assets/img/exit-label.svg"
import flechaIcono from "../../Assets/img/flechaIcono.svg"

interface Props {
    handleCloseModal(): void;
    open: boolean;
    setSelectedReports(selectedReports: any[]): void;
    selectedReports: any[];
}

interface State {
    selectedReports: any[];
    searchTerm: string;
}


export class ModalRptExpress extends Component<Props, State>  {

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedReports: this.props.selectedReports,
            searchTerm: ''
        }
    };

    handleCheckboxChange = (report: any) => {
        const { selectedReports } = this.state;
        const newSelectedReports = [...selectedReports];
        if (selectedReports.includes(report)) {
            newSelectedReports.splice(selectedReports.indexOf(report), 1);
        } else {
            newSelectedReports.push(report);
        }
        this.setState({ selectedReports: newSelectedReports });
    }

    handleSearchTermChange = (event: any) => {
        this.setState({ searchTerm: event.target.value });
    }


    render() {
        const { handleCloseModal, setSelectedReports, open } = this.props;
        const { selectedReports, searchTerm } = this.state;

        const filteredReports = expressReports.filter(report => {
            return report.reporte.toLowerCase().includes(searchTerm.toLowerCase())
        });

        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={open}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title">
                                <img style={{ marginRight: 35, marginLeft: 20 }} src={flechaIcono} alt="" />
                                <h2 id="transition-modal-title" style={{ marginLeft: 0 }}>{I18N.get("txtAdditionalReports")}</h2>
                            </div>
                            <button className="btn-exit" onClick={handleCloseModal} >
                                <img src={exitLabel} alt="exit.jpg"></img>
                            </button>
                        </div>

                        <TextField
                            label={I18N.get("txtSearchReports")}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            value={searchTerm}
                            onChange={this.handleSearchTermChange}
                            className="seachInput"
                        />

                        <div className="LabelReportFilter">
                            {selectedReports.map((report, key) => {
                                return (
                                    <div key={key}>
                                        <LabelReportFilter
                                            selectedReport={report} onClose={() => {
                                                const newSelectedReports = [...selectedReports];
                                                newSelectedReports.splice(selectedReports.indexOf(report), 1);
                                                this.setState({ selectedReports: newSelectedReports });
                                            }} ></LabelReportFilter>
                                    </div>
                                )
                            })}
                        </div>

                        <TableContainer component={Paper} style={{ maxHeight: 400, overflow: 'auto', boxShadow: 'none' }}>
                            <Table className="table" size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Grupo</TableCell>
                                        <TableCell>Subgrupo</TableCell>
                                        <TableCell>Reporte</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredReports.map((row, key) => {
                                        const isSelected = selectedReports.includes(row);
                                        return (
                                            <TableRow key={key}>
                                                <TableCell component="th" scope="row">
                                                    <input type="checkbox" checked={isSelected} disabled={selectedReports.length >= 6 && !isSelected} onChange={() => this.handleCheckboxChange(row)} />
                                                </TableCell>
                                                <TableCell>{row.grupo}</TableCell>
                                                <TableCell>{row.subgrupo}</TableCell>
                                                <TableCell>{row.reporte}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="modal-buttons">
                            <Button variant="contained" color="default" type="button" onClick={() => {
                                handleCloseModal();
                            }}>
                                {I18N.get("btnCancelModalReportsExpress")}
                            </Button>
                            <Button variant="contained" color="primary" type="button" onClick={() => {
                                setSelectedReports(selectedReports);
                                handleCloseModal();
                            }} >
                                {I18N.get("btnAcceptModalReportsExpress")}
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal >
        );
    }
}
