import "./WizardForm.scss";

import React, {
  Component,
  FormEvent,
  Fragment,
  RefObject,
  useEffect,
} from "react";

import ReCAPTCHA from "react-google-recaptcha";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { I18N } from "../../i18n/I18N";
import { textfieldTheme } from "../Textfield/TextfieldTheme";
import { textfieldStyle } from "../Textfield/TextfieldStyle";
import { WizardFormState } from "./WizardFormState";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { CustomDialog } from "../CustomDialog/CustomDialog";
import { CustomDialogProps } from "../CustomDialog/CustomDialogProps";
import InputMask from "react-input-mask";
import { SimpleMap } from "./SimpleMap";
import { Add, Delete, PhotoCamera } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { ModalRptExpress } from "../ModalRptExpress/ModalRptExpress";

const COUNTRIES: SimpleMap[] = [];
const USER_TYPES: SimpleMap[] = [];
const SYSTEM_TYPES: SimpleMap[] = [];
const FRANCHISE_TYPES: SimpleMap[] = [];

function formatString(mask: string, number: string): string {
  let s = ''+number; 
  
  let r = '';
  
  for (var im=0, is = 0; im<mask.length && is<s.length; im++) {
    r += mask.charAt(im)=='9' ? s.charAt(is++) : mask.charAt(im);
  }

  return r;
}    

export class WizardForm extends Component<any, WizardFormState> {
  emailRegex = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}");
  charsAndNumbers = new RegExp("^[a-zA-Z0-9]");
  letterRegex = new RegExp("[a-zA-Z]");
  numberRegex = new RegExp("[0-9]");
  recaptchaRef: RefObject<ReCAPTCHA> = React.createRef();
  companyInput: React.RefObject<HTMLInputElement>;

  textfieldInputProps = {
    classes: {
      root: this.props.classes.root,
    },
  };

  textfieldLabelProps = {
    classes: {
      root: this.props.classes.label,
      focused: this.props.classes.focused,
    },
  };

  constructor(props: any) {
    super(props);
    this.companyInput = React.createRef();
    this.state = {
      loading: false,
      country: {
        value: "",
        valid: true,
        text: (value: string | undefined) =>
          value === "" ? I18N.get("requiredField") : "",
        validator: (value: SimpleMap) => value,
        disabled: false,
      },
      identification: {
        value: "",
        valid: true,
        text: this.checkIdentificationText,
        validator: (value: string | undefined) =>
          value === ""
            ? false
            : value
            ? new RegExp(this.getIdTypeProperty("validatorSecundario")).test(
                value
              )
            : false,
        disabled: false,
      },
      companyName: {
        value: "",
        valid: true,
        text: (value: string | undefined) =>
          value === "" ? I18N.get("requiredField") : "",
        validator: (value: string) => this.charsAndNumbers.test(value),
        disabled: () =>
          this.state.country.value
            ? this.state.country.value.key !== "ES"
            : false,
      },
      systemType: {
        value: "",
        valid: true,
        text: (value: string | undefined) =>
          value === "" ? I18N.get("requiredField") : "",
        validator: (value: any) => value,
        disabled: false,
      },
      franchiseType: {
        value: "",
        valid: true,
        text: (value: string | undefined) =>
          value === "" ? I18N.get("requiredField") : "",
        validator: (value: any) => value,
        disabled: false,
      },
      email: {
        value: "",
        valid: true,
        text: (value: string | undefined) =>
          value === ""
            ? I18N.get("requiredField")
            : !this.state.email.valid
            ? I18N.get("invalidMailFormat")
            : "",
        validator: (value: string) => this.emailRegex.test(value),
        disabled: false,
      },
      customerCode: {
        value: "",
        valid: true,
        text: (value: string | undefined) =>
          value === "" ? I18N.get("requiredField") : "",
        validator: (value: string) => this.charsAndNumbers.test(value),
        disabled: false,
      },
      userTypes: [
        {
          valid: true,
          value: { codigo: undefined, cantidad: 0 },
          text: (value: any) =>
            !value.codigo || value.cantidad === 0
              ? "Ambos campos son requeridos"
              : "",
          validator: (value: any) => value.codigo && value.cantidad > 0,
          disabled: false,
        },
      ],
      captcha: {
        value: "",
        valid: true,
        text: (value: string | null | undefined) =>
          !(value && value.length > 0) ? I18N.get("requiredCaptcha") : "",
        validator: (value: string | null | undefined) =>
          value && value.length > 0 ? true : false,
        disabled: false,
      },
      dialogTitle: () =>
        (this.state.responseActions.ok as any)
          ? "Proceso de Creación Finalizado"
          : this.state.responseActions &&
            (this.state.responseActions.value as any).repeatedIdentifier
          ? ""
          : "Ups, hubo un error",
      dataResponse: undefined,
      responseActions: {
        open: false,
        value: "",
        ok: false,
        partialSuccess: false,
        wizardResult: undefined,
      },
      registryResponse: undefined,
      modalOpen: false,
      selectedReports: [],
      identificationOnFocus: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  setDialgoState() {
    this.setState({
      responseActions: {
        open: false,
        value: "",
        ok: false,
        partialSuccess: false,
        wizardResult: undefined,
      },
    });
  }

  getData = () => {
    this.setState({ loading: true });

    fetch("/wizard", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        this.setState({ loading: false });
        switch (response.status) {
          case 200:
          case 489:
          case 488:
            response.text().then((WizardDTO) => {
              this.setState({
                dataResponse: {
                  open: false,
                  valid: true,
                  value: JSON.parse(WizardDTO),
                },
              });

              let dataResponse = this.state.dataResponse;

              this.fillMap(
                dataResponse && dataResponse.value
                  ? dataResponse.value.tiposUsuarios
                  : [],
                USER_TYPES,
                "codigoTipos"
              );
              this.fillMap(
                dataResponse && dataResponse.value
                  ? dataResponse.value.tiposSistemas
                  : [],
                SYSTEM_TYPES,
                "id"
              );
              this.fillMap(
                dataResponse && dataResponse.value
                  ? dataResponse.value.tiposFranquicias
                  : [],
                FRANCHISE_TYPES,
                "id"
              );
              this.fillMap(
                dataResponse && dataResponse.value
                  ? dataResponse.value.paises
                  : [],
                COUNTRIES,
                "codigo"
              );

              //default values
              this.setState(
                (prevState) =>
                  ({
                    country: {
                      ...(prevState as any).country,
                      value: COUNTRIES[0],
                    },
                    franchiseType: {
                      ...(prevState as any).franchiseType,
                      value: FRANCHISE_TYPES[0],
                    },
                  } as any)
              );
            });
            break;
          case 401:
            response.text().then((redirectURL) => {
              this.setState({
                dataResponse: {
                  open: true,
                  valid: false,
                  value: undefined,
                },
              });
            });
            break;
          case 408:
          case 522:
          case 524:
          case 525:
          default:
            this.setState({
              dataResponse: {
                open: true,
                valid: false,
                value: undefined,
              },
            });
            break;
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          dataResponse: {
            open: true,
            valid: false,
            value: undefined,
          },
        });
      });
  };

  getCompanyName = () => {
    const registryData = this.state.registryResponse;

    if (registryData
        && registryData.companyName) {
          return  registryData.companyName
    }
    
    return this.state.companyName.value;
  }

  submitData = (event: FormEvent): any => {
    let form: any = event.target;

    let validId = this.state.identification.valid;
    let validCustomerCode = this.isValid(form, "customerCode");
    let validEmail = this.isValid(form, "email");
    let franchiseType = this.isValid(form, "franchiseType");
    let systemType = this.isValid(form, "systemType");
    let companyName = this.isValid(form, "companyName");
    let validIdentification = this.isValid(form, "identification");
    let country = this.isValid(form, "country");
    let validCaptcha = this.isCaptchaValid();

    let validUserTypes = true;

    this.state.userTypes.forEach((el, i) => {
      let userTypes = this.state.userTypes;

      userTypes[i].valid = this.state.userTypes[i].validator(
        this.state.userTypes[i].value
      );

      this.setState({ userTypes });

      if (!userTypes[i].valid) {
        validUserTypes = false;
      }
    });

    if (
      validEmail &&
      validCaptcha &&
      companyName &&
      country &&
      systemType &&
      franchiseType &&
      validIdentification &&
      validCustomerCode &&
      validUserTypes && 
      validId
    ) {
      this.setState({ loading: true });

      let userTypes = this.state.userTypes.map((el) => {
        return {
          id: el.value.codigo,
          cantidad: el.value.cantidad,
        };
      });

      let body = JSON.stringify({
        captcha: this.state.captcha.value,
        cantidad_usuarios: userTypes,
        codigo_cliente: this.state.customerCode.value,
        email: this.state.email.value,
        identifier: {
          id: this.state.identification.value.replace(/-/g, ""),
          codigo_postal: this.state.registryResponse
            ? this.state.registryResponse.codigoPostal
            : null,
          domicilio: this.state.registryResponse
            ? this.state.registryResponse.domicilio
            : null,
          localidad: this.state.registryResponse
            ? this.state.registryResponse.localidad
            : null,
          provincia: this.state.registryResponse
            ? this.state.registryResponse.provincia
            : null,
          razon_social: this.getCompanyName(),
        },
        idioma: "es",
        pais: this.state.country.value.key,
        tipo_franquicia: this.state.franchiseType.value.key,
        tipo_sistema: this.state.systemType.value.key,
        reportes_express: this.state.selectedReports.map((el) => el.nombreRPT),
      });

      fetch("/wizard", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: body,
      })
        .then((response) => {
          this.setState({ loading: false });

          switch (response.status) {
            case 206:
              response.text().then((WizardResultDTO) => {
                this.setState({
                  responseActions: {
                    open: true,
                    value: "El proceso fue completado con algunos errores.",
                    ok: true,
                    partialSuccess: true,
                    wizardResult: JSON.parse(WizardResultDTO),
                  },
                });
              });
              break;
            case 200:
              response.text().then((WizardResultDTO) => {
                this.setState({
                  responseActions: {
                    open: true,
                    value: "El proceso fue realizado exitosamente.",
                    ok: true,
                    partialSuccess: false,
                    wizardResult: JSON.parse(WizardResultDTO),
                  },
                });
              });
              break;
            case 489:
              response.text().then((redirectURL) => {
                window.location.replace(
                  redirectURL +
                    "&useCookie=false&emailUser=" +
                    form["email"].value
                );
              });
              break;
            case 500:
              response.text().then((errorDTO) => {
                this.setState({
                  responseActions: {
                    open: true,
                    value: JSON.parse(errorDTO).message,
                    ok: false,
                    partialSuccess: false,
                    wizardResult: undefined,
                  },
                });
              });
              break;
            case 408:
            case 522:
            case 524:
            case 525:
            default:
              this.setState({
                responseActions: {
                  open: true,
                  value: "error",
                  ok: false,
                  partialSuccess: false,
                  wizardResult: undefined,
                },
              });
              break;
          }
        })
        .catch(() => {
          this.setState({
            loading: false,
            responseActions: {
              open: true,
              value: "error",
              ok: false,
              partialSuccess: false,
              wizardResult: undefined,
            },
          });
        });
    } else {
      if (!companyName) {
        this.setState((prevState) => ({
          companyName: {
            ...prevState.companyName,
            disabled: () => false,
          },
        }));
      }
    }
  };

  submitDataWhenFocusOnIdentificationIsLost = (event: FormEvent): any => {
    setTimeout(() => {
      if (this.state.identificationOnFocus) {
       this.submitDataWhenFocusOnIdentificationIsLost(event);
      } else {
        if (this.state.identification.valid) {
        this.submitData(event);
        }
      }
     }, 100);
  }

  submit = async (event: FormEvent): Promise<void> => {
    event.persist();
    event.preventDefault();

    if(this.state.identificationOnFocus) {
      await this.identifierChange(this.state.identification.value);
    
      this.submitDataWhenFocusOnIdentificationIsLost(event);
    } else {
      this.submitData(event);
    }
  };

  isValid = (form: any, property: string): boolean => {
    if (!(this.state as any)[property].validator(form[property].value)) {
      this.setState(
        (prevState) =>
          ({
            [property]: {
              ...(prevState as any)[property],
              valid: false,
            },
          } as any)
      );

      form[property].focus();

      return false;
    }
    return true;
  };

  isCaptchaValid = () => {
    return true;
  };

  textfieldChange = (value: string | any, property: string) => {
    const aState = (this.state as any);

    const identification = aState[property];
    
    this.setState(
      (prevState) =>
        ({
          [property]: {
            ...(prevState as any)[property],
            value: value,
            valid: identification.validator(value),
          },
        } as any)
    );
  };

  dialogText = () => {
    let generalError =
      "Ocurrió un error al comunicarse con el servidor, intente nuevamente más tarde.";
    if (
      this.state.responseActions.ok &&
      !this.state.responseActions.partialSuccess
    ) {
      return "El proceso fue completado sin errores.";
    }
    if (
      this.state.responseActions.ok &&
      this.state.responseActions.partialSuccess
    ) {
      return "El proceso fue completado con algunos Errores.";
    } else {
      if (this.state.responseActions.open) {
        if (this.state.responseActions.value === "error") {
          return generalError;
        } else {
          let response = this.state.responseActions.value as string;
          if (response) {
            return response;
          }
          return generalError;
        }
      }
    }
  };

  partialSucces = () => {
    return this.state.responseActions.ok ? (
      <Fragment>
        <Typography color="textSecondary" variant="h5">
          <div>
            Mail de Usuario:{" "}
            {this.state.responseActions.wizardResult &&
            this.state.responseActions.wizardResult.userEmail
              ? this.state.responseActions.wizardResult.userEmail
              : null}
          </div>
          <div>
            Creación BD{" "}
            {this.statusProcess(
              this.state.responseActions.wizardResult &&
                this.state.responseActions.wizardResult.dbCreated
            )}
          </div>
          <div>
            Creación de Usuario de Jira{" "}
            {this.statusProcess(
              this.state.responseActions.wizardResult &&
                this.state.responseActions.wizardResult.jiraUserCreated
            )}
          </div>
          Envío Mail{" "}
          {this.statusProcess(
            this.state.responseActions.wizardResult &&
              this.state.responseActions.wizardResult.emailSent
          )}
          <div></div>
        </Typography>
      </Fragment>
    ) : null;
  };

  statusProcess = (itemStatus: boolean | undefined) => {
    return itemStatus === true ? (
      <span className="list-ok">
        <strong>✓</strong>
      </span>
    ) : (
      <span className="list-error">
        <strong>X</strong>
      </span>
    );
  };

  fillMap = (response: any, map: SimpleMap[], property: string) => {
    response.forEach((item: any) => {
      map.push({ key: item[property], value: item.descripcion });
    });
  };

  getCountryInfo(key: string) {
    if (
      this.state.dataResponse &&
      this.state.dataResponse.value &&
      this.state.dataResponse.value.paises
    ) {
      const paises = this.state.dataResponse.value.paises;

      for (const index in paises) {
        if (paises[index].codigo === key) {
          return paises[index];
        }
      }
    }
  }

  getIdTypeProperty(property: string): string {
    let pais = this.state.country.value
      ? this.getCountryInfo(this.state.country.value.key)
      : undefined;

    return pais && pais.tipo && (pais.tipo as any)[property]
      ? (pais.tipo as any)[property]
      : "";
  }

  addUserType() {
    let userTypes = this.state.userTypes;

    userTypes.push({
      ...this.state.userTypes[0],
      value: {
        codigo: undefined,
        cantidad: 0,
      },
    });

    this.setState({ userTypes });
  }

  changeUserType(value: any, i: number, property: string) {
    let userTypes = this.state.userTypes;

    userTypes[i].value[property] = Number(value);

    this.setState({ userTypes });
  }

  removeUserType(i: any) {
    let userTypes = this.state.userTypes;

    if (i > 0) {
      userTypes.splice(i, 1);
    }

    this.setState({ userTypes });
  }

  findUserType(codigo: number): SimpleMap | undefined {
    USER_TYPES.forEach((el, i) => {
      if (USER_TYPES[i].key === String(codigo)) {
        return USER_TYPES[i];
      }
    });

    return undefined;
  }

  identifierChange(clientId: any) {
    if (Number.isInteger(clientId)) {
      clientId = this.state
                     .identification
                     .value;
    }

    const formatMask = this.getIdTypeProperty("mascaraSecundaria");

    if (formatMask) {
      clientId = formatString(formatMask,
        clientId.split("-").join(""));
    }

    this.textfieldChange(clientId,
                        "identification");
    
    this.setState((prevState) => ({
      companyName: {
        ...prevState.companyName,
        disabled: () =>
          this.state.country.value
            ? this.state.country.value.key !== "ES"
            : false,
      },
    }));


    setTimeout(async () => {
      if (this.state.country.valid
        && this.state.identification.value
        && this.state.country.value.key !== "ES") {
      await fetch(
          "/padron/" +
            this.state.country.value.key +
            "/" +
            this.state.identification.value,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then(async (response) => {
            if (!response.ok) {
              throw Error(response.statusText);
            }

            return response.json();
          })
          .then((response) => {
            const companyName = response.companyName;

            const companyRecognized = companyName.length !== 0;

            const validId = response.validId;

            this.setState((prevState) => ({
              registryResponse: response,

              companyName: {
                ...prevState.companyName,
                value: companyName ? companyName : prevState.companyName.value,
                disabled: () => validId && companyRecognized ? true : false,
                valid: true
              },

              identification: {
                ...prevState.identification,
                valid: validId
              },

              identificationOnFocus: false
            }));
          })
          .catch((error) => {
            this.setState((prevState) => ({
              identification: {
                ...prevState.identification,
                valid: false
              },

              identificationOnFocus: false
            }));
            console.error(error);
          });
    
        } else if (this.state.country.value.key === "ES") {
          this.setState((prevState) => ({
            identificationOnFocus: false
          }));
        }
      });
  }

  handleRefresh = () => {
    window.location.reload(false);
  };

  handleOpenModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  setSelectedReports = (reports: any) => {
    this.setState({ selectedReports: reports });
  };

  validateSystemType = () => {
    if (this.state.systemType.value.key === 3) {
      return true;
    } else {
      if (this.state.selectedReports.length > 0) {
        this.setState({ selectedReports: [] });
      }
      return false;
    }
  };

  checkIdentificationText = (value: string | undefined) => {
    if (! value) {
        return I18N.get("requiredField");
    }

    return I18N.get("invalidIdentifier");
  }

  render() {
    const customDProps: CustomDialogProps = {
      textPrimary: this.state.dialogTitle(),
      textSecondary: this.dialogText(),
      onClick: () => this.getData(),
      open: this.state.dataResponse != null && this.state.dataResponse.open,
    };

    return (
      <form className="wizard-form" autoComplete="off" onSubmit={this.submit}>
        <MuiThemeProvider theme={textfieldTheme}>
          <Typography className="main-title" align="center">
            {I18N.get("wizardFormTitle")}
          </Typography>

          {/* COUNTRY TEXTFIELD */}

          <Autocomplete
            id="country"
            value={this.state.country.value}
            options={COUNTRIES}
            className="form-field"
            getOptionLabel={(option) => (option.value ? option.value : "")}
            getOptionSelected={(option, value) => option.key === value.key}
            renderInput={(params) => (
              <TextField
                {...params}
                label="País"
                error={!this.state.country.valid}
                variant="outlined"
                className="form-field"
                helperText={
                  !this.state.country.valid
                    ? this.state.country.text(this.state.country)
                    : ""
                }
                InputLabelProps={this.textfieldLabelProps}
              />
            )}
            onChange={async (event, newValue) => {
              event.persist();

              if (newValue) {
                await this.textfieldChange(newValue, "country");
                
                const stateValue = this.state
                                       .identification
                                       .value;

                if (stateValue) {
                  await this.identifierChange(stateValue);
                }
              }
            }}
          />

          {/* ID TEXTFIELD */}

          <InputMask
            mask={this.getIdTypeProperty("mascaraSecundaria")}
            onFocus={() => {
              this.setState(previous => ({
                ...previous,
                identificationOnFocus: true
              }))
            }}
            onBlur={(event) => this.identifierChange(event.target.value)}
            disabled={this.state.identification.disabled}
            maskPlaceholder="_"
          >
            {() => (
              <TextField
                id="identification"
                name="identification"
                variant="outlined"
                className="form-field"
                label={this.getIdTypeProperty("abreviacion") || "ID"}
                error={!this.state.identification.valid}
                disabled={this.state.identification.disabled}
                helperText={
                  !this.state.identification.valid
                    ? this.state.identification.text(
                        this.state.identification.value
                      )
                    : ""
                }
                defaultValue={this.state.identification.value}
                InputLabelProps={this.textfieldLabelProps}
                inputProps={{ maxLength: 20 }}
              />
            )}
          </InputMask>

          {/* COMPANY NAME TEXTFIELD */}

          <TextField
            id="companyName"
            name="companyName"
            label={I18N.get("txtCompanyName")}
            error={!this.state.companyName.valid}
            value={this.state.companyName.value}
            variant="outlined"
            onChange={(event) =>
              this.textfieldChange(event.target.value, "companyName")
            }
            disabled={this.state.companyName.disabled()}
            className="form-field"
            helperText={
              !this.state.companyName.valid
                ? this.state.companyName.text(this.state.companyName.value)
                : ""
            }
            inputProps={{ maxLength: 60 }}
            InputProps={this.textfieldInputProps}
            InputLabelProps={this.textfieldLabelProps}
            inputRef={this.companyInput}
          />

          {/* SYSTEM TYPE TEXTFIELD */}

          <Autocomplete
            id="systemType"
            value={this.state.systemType.value}
            //onBlur={event => setTimeout(() => this.textfieldChange(this.state.systemType.value, "systemType"))}
            options={SYSTEM_TYPES}
            className="form-field"
            getOptionLabel={(option) => (option.value ? option.value : "")}
            getOptionSelected={(option, value) =>
              option && value && value !== "" ? option.key === value.key : false
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de Sistema"
                error={!this.state.systemType.valid}
                variant="outlined"
                className="form-field"
                helperText={
                  !this.state.systemType.valid
                    ? this.state.systemType.text(this.state.systemType.value)
                    : ""
                }
                InputLabelProps={this.textfieldLabelProps}
              />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                this.textfieldChange(newValue, "systemType");
              }
            }}
          />

          {/* FRANCHISE TYPE TEXTFIELD */}

          <Autocomplete
            id="franchiseType"
            value={this.state.franchiseType.value}
            //onBlur={event => setTimeout(() => this.textfieldChange(this.state.systemType.value, "systemType"))}
            options={FRANCHISE_TYPES}
            className="form-field"
            getOptionLabel={(option) => (option.value ? option.value : "")}
            getOptionSelected={(option, value) =>
              option && value && value !== "" ? option.key === value.key : false
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de Franquicia"
                error={!this.state.franchiseType.valid}
                variant="outlined"
                className="form-field"
                helperText={
                  !this.state.franchiseType.valid
                    ? this.state.franchiseType.text(
                        this.state.franchiseType.value
                      )
                    : ""
                }
                InputLabelProps={this.textfieldLabelProps}
              />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                this.textfieldChange(newValue, "franchiseType");
              }
            }}
          />

          {/* EMAIL TEXTFIELD */}

          <TextField
            id="email"
            name="email"
            label={I18N.get("txtEmail")}
            error={!this.state.email.valid}
            variant="outlined"
            className="form-field"
            onChange={(event) =>
              this.textfieldChange(event.target.value, "email")
            }
            helperText={
              !this.state.email.valid
                ? this.state.email.text(this.state.email.value)
                : ""
            }
            inputProps={{ maxLength: 60 }}
            InputProps={this.textfieldInputProps}
            defaultValue={this.state.email.value}
            InputLabelProps={this.textfieldLabelProps}
          />

          {/* CUSTOMER CODE TEXTFIELD */}

          <TextField
            id="customerCode"
            name="customerCode"
            label={I18N.get("txtCustomerCode")}
            error={!this.state.customerCode.valid}
            variant="outlined"
            onChange={(event) =>
              this.textfieldChange(event.target.value, "customerCode")
            }
            className="form-field"
            helperText={
              !this.state.customerCode.valid
                ? this.state.customerCode.text(this.state.customerCode.value)
                : ""
            }
            inputProps={{ maxLength: 20 }}
            InputProps={this.textfieldInputProps}
            defaultValue={this.state.customerCode.value}
            InputLabelProps={this.textfieldLabelProps}
          />

          {/* USER TYPE FIELDS */}

          {this.state.userTypes.map((el: any, i: any) => (
            <div key={el} className="inline">
              <Autocomplete
                id={"user_type_" + i}
                value={this.findUserType(this.state.userTypes[i].value.codigo)}
                options={USER_TYPES}
                className="inline-combo"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo de Usuario"
                    error={!this.state.userTypes[i].valid}
                    variant="outlined"
                    className="form-field"
                    helperText={
                      !this.state.userTypes[i].valid
                        ? this.state.userTypes[i].text(
                            this.state.userTypes[i].value
                          )
                        : ""
                    }
                  />
                )}
                getOptionLabel={(option) => option.value}
                onChange={(event, value) => {
                  if (value) {
                    this.changeUserType(value.key, i, "codigo");

                    setTimeout(() => {
                      let userTypes = this.state.userTypes;

                      userTypes[i].valid = this.state.userTypes[i].validator(
                        this.state.userTypes[i].value
                      );

                      this.setState({ userTypes });
                    });
                  }
                }}
              />

              <TextField
                id={"user_type_amount_" + i}
                type="number"
                name={"user_type_amount_" + i}
                label="Cantidad"
                error={!this.state.userTypes[i].valid}
                value={this.state.userTypes[i].value.cantidad}
                variant="outlined"
                onChange={(event: any) => {
                  this.changeUserType(event.target.value, i, "cantidad");

                  setTimeout(() => {
                    let userTypes = this.state.userTypes;

                    userTypes[i].valid = this.state.userTypes[i].validator(
                      this.state.userTypes[i].value
                    );

                    this.setState({ userTypes });
                  });
                }}
                className="inline-textfield"
                inputProps={{ maxLength: 5, min: 0 }}
                InputProps={this.textfieldInputProps}
                InputLabelProps={this.textfieldLabelProps}
              />

              <div
                hidden={!(this.state.userTypes.length - 1 === i)}
                className="button-container"
              >
                <IconButton onClick={() => this.addUserType()}>
                  <Add />
                </IconButton>

                <IconButton
                  hidden={i === 0}
                  onClick={() => this.removeUserType(i)}
                >
                  <Delete />
                </IconButton>
              </div>
            </div>
          ))}

          {/* Button express reports */}
          {this.validateSystemType() && (
            <Button
              variant="contained"
              color="primary"
              type="button"
              className="extraLargeButton form-field"
              onClick={this.handleOpenModal}
            >
              {I18N.get("btnAdditionalReports")}
            </Button>
          )}

          {/* CAPTCHA */}

          <div className="captcha">
            <ReCAPTCHA
              ref={this.recaptchaRef}
              sitekey="6LdZL5oUAAAAADzWIg4vPfzUdkXHTCxBDYvXRAUt"
              onChange={(value) =>
                this.setState((prevState) => ({
                  captcha: {
                    ...prevState.captcha,
                    value: value !== null ? value : "",
                  },
                }))
              }
            />
            <label className="label-error" hidden={this.state.captcha.valid}>
              {this.state.captcha.text(this.state.captcha.value)}
            </label>
          </div>

          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            className={this.props.classes.button}
          >
            {I18N.get("txtRegister")}
          </Button>

          <Dialog open={this.state.loading} disableEscapeKeyDown>
            <DialogTitle>
              Estamos verificando la conexión con el servidor
            </DialogTitle>
            <DialogContent>
              <div className="processing">
                <CircularProgress size="56px" />
              </div>
              <Typography color="textSecondary" align="center" variant="h6">
                Por favor, aguarde un instante
              </Typography>
            </DialogContent>
          </Dialog>

          <Dialog open={this.state.responseActions.open} disableEscapeKeyDown>
            <DialogContent>
              <div className="processing">
                <img
                  className="warning"
                  src={require("../../images/warning.png")}
                  alt="warning"
                />
              </div>
              <span className="dialog-error">
                <Typography align="center" variant="h6">
                  {this.state.dialogTitle()}
                </Typography>
                <Typography color="textSecondary" variant="h3">
                  {this.dialogText()}
                </Typography>
                {this.partialSucces()}
              </span>
              <div className="footer">
                <Button
                  className={this.props.classes.flatButton}
                  onClick={(event: any) => {
                    if (!this.state.responseActions.ok) {
                      this.setState({
                        responseActions: {
                          open: false,
                          value: "",
                          ok: false,
                          partialSuccess: false,
                          wizardResult: undefined,
                        },
                      });
                    } else {
                      this.setState({
                        responseActions: {
                          open: false,
                          value: "",
                          ok: false,
                          partialSuccess: false,
                          wizardResult: undefined,
                        },
                      });
                      this.handleRefresh();
                    }
                  }}
                >
                  {I18N.get("txtAccept")}
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <CustomDialog {...customDProps} />
        </MuiThemeProvider>
        {this.state.modalOpen && (
          <ModalRptExpress
            open={this.state.modalOpen}
            handleCloseModal={this.handleCloseModal}
            setSelectedReports={this.setSelectedReports}
            selectedReports={this.state.selectedReports}
          ></ModalRptExpress>
        )}
      </form>
    );
  }
}

export default withStyles(textfieldStyle)(WizardForm);
