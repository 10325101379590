import {makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const CustomDialogStyle = makeStyles((theme: Theme) => createStyles({
    flatButton: {
        height: 40,
        margin: "auto",
        padding: "8px 24px",
        fontSize: 14,
        boxShadow: "none",
        fontWeight: "bold",
        borderRadius: 24,
        color: "#4285f4",
        "&:hover": {
            backgroundColor: "rgba(76, 144, 245, 0.12)"
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "rgba(76, 144, 245, 0.24)"
        }
        }
    })
)
