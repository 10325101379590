const expressReports = [
    {
        nombreRPT: "Cry_ReportERP002.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Resumen de Ventas por Provincia"
    },
    {
        nombreRPT: "Cry_ReportERP003.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Resumen de Ventas por Vendedor (Según Cliente)"
    },
    {
        nombreRPT: "Cry_ReportERP003B.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Resumen de Ventas por Vendedor (Según Comprobante)"
    },
    {
        nombreRPT: "Cry_ReportERP003C.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Resumen de Ventas por Usuario(Según Comprobante)"
    },
    {
        nombreRPT: "Cry_ReportERP004.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Resumen de Ventas por Zona"
    },
    {
        nombreRPT: "Cry_ReportERP006.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Resumen de Ventas por Condición de Venta"
    },
    {
        nombreRPT: "Cry_ReportERP008.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Ventas por Zona"
    },
    {
        nombreRPT: "Cry_ReportERP009.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Ventas por Localidad"
    },
    {
        nombreRPT: "Cry_ReportERP010.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Ventas por Provincia"
    },
    {
        nombreRPT: "Cry_ReportERP011.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Ventas por Vendedor (Según Cliente)"
    },
    {
        nombreRPT: "Cry_ReportERP011B.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Ventas por Vendedor (Según Comprobante)"
    },
    {
        nombreRPT: "Cry_ReportERP011C.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Ventas por Vendedor (Según Comprobante)"
    },
    {
        nombreRPT: "Cry_ReportERP012.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Ventas por Artículo"
    },
    {
        nombreRPT: "Cry_ReportERP013B.rpt",
        grupo: "VENTAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Subdiario de IVA Ventas - Con Cont. de Comp."
    },
    {
        nombreRPT: "Cry_ReportERP014.rpt",
        grupo: "VENTAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Lista de Comprobantes de Venta por Tipo"
    },
    {
        nombreRPT: "Cry_ReportERP015.rpt",
        grupo: "VENTAS",
        subgrupo: "Cálculos de Comisiones",
        reporte: "Comisiones por Vendedor (de Clientes)"
    },
    {
        nombreRPT: "Cry_ReportERP015B.rpt",
        grupo: "VENTAS",
        subgrupo: "Cálculos de Comisiones",
        reporte: "Comisiones por Vendedor (de Facturas)"
    },
    {
        nombreRPT: "Cry_ReportERP016.rpt",
        grupo: "VENTAS",
        subgrupo: "Cálculos de Comisiones",
        reporte: "Comisiones por Vendedor, Familia y Artículo (de Clientes)"
    },
    {
        nombreRPT: "Cry_ReportERP016B.rpt",
        grupo: "VENTAS",
        subgrupo: "Cálculos de Comisiones",
        reporte: "Comisiones por Vendedor, Familia y Artículo (de Facturas)"
    },
    {
        nombreRPT: "Cry_ReportERP017B.rpt",
        grupo: "VENTAS",
        subgrupo: "Pedidos - Remitos - Cotizaciones",
        reporte: "Lista de Remitos por Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP018.rpt",
        grupo: "VENTAS",
        subgrupo: "Pedidos - Remitos - Cotizaciones",
        reporte: "Lista de Pedidos por Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP019.rpt",
        grupo: "VENTAS",
        subgrupo: "Pedidos - Remitos - Cotizaciones",
        reporte: "Lista de Cotizaciones por Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP021.rpt",
        grupo: "VENTAS",
        subgrupo: "Pedidos - Remitos - Cotizaciones",
        reporte: "Lista de Pendientes por Artículo"
    },
    {
        nombreRPT: "Cry_ReportERP022.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Clientes",
        reporte: "Lista de Comisiones sobre Cobranzas"
    },
    {
        nombreRPT: "Cry_ReportERP023.rpt",
        grupo: "VENTAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Lista de Notas de Crédito Emitidas"
    },
    {
        nombreRPT: "Cry_ReportERP024.rpt",
        grupo: "VENTAS",
        subgrupo: "Cálculos de Comisiones",
        reporte: "Comisiones por Vendedor sobre Artículos"
    },
    {
        nombreRPT: "Cry_ReportERP025.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Ventas por Familia y Artículos"
    },
    {
        nombreRPT: "Cry_ReportERP026.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumen de Ventas",
        reporte: "Lista de Ventas por Sucursal (Incluye Litros)"
    },
    {
        nombreRPT: "Cry_ReportERP027.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Ventas por Artículo con Fabricante"
    },
    {
        nombreRPT: "Cry_ReportERP028.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Ventas por Familia y Artículos Con Fabricante"
    },
    {
        nombreRPT: "Cry_ReportERP029.rpt",
        grupo: "VENTAS",
        subgrupo: "Pedidos - Remitos - Cotizaciones",
        reporte: "Lista de Pendientes de Acopio por Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP031.rpt",
        grupo: "VENTAS",
        subgrupo: "Cálculos de Comisiones",
        reporte: "Comisiones por Vendedor, Grupo y Artículo (de Facturas)"
    },
    {
        nombreRPT: "Cry_ReportERP031B.rpt",
        grupo: "VENTAS",
        subgrupo: "Cálculos de Comisiones",
        reporte: "Comisiones por Vendedor, Grupo y Artículo (de Facturas)"
    },
    {
        nombreRPT: "Cry_ReportERP033.rpt",
        grupo: "VENTAS",
        subgrupo: "Pedidos - Remitos - Cotizaciones",
        reporte: "Lista de Pendientes por Vendedor y Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP033B.rpt",
        grupo: "VENTAS",
        subgrupo: "Pedidos - Remitos - Cotizaciones",
        reporte: "Listado de Pendientes por Vendedor y Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP034.rpt",
        grupo: "VENTAS",
        subgrupo: "Informes Varios",
        reporte: "Lista de Descuentos Aplicados en Facturas"
    },
    {
        nombreRPT: "Cry_ReportERP035.rpt",
        grupo: "VENTAS",
        subgrupo: "Informes Varios",
        reporte: "Reimpresión de Facturas"
    },
    {
        nombreRPT: "Cry_ReportERP036B.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Lista de Ventas por Forma de Pago(Según Comprobante)"
    },
    {
        nombreRPT: "Cry_ReportERP037.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Utilidad por Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP038.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Utilidad por Cliente y Comprobante"
    },
    {
        nombreRPT: "Cry_ReportERP039.rpt",
        grupo: "VENTAS",
        subgrupo: "Ventas y Compras por Artículo",
        reporte: "Ventas y Compras por Artículo"
    },
    {
        nombreRPT: "Cry_ReportERP040.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Estadísticas de Ventas por Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP041.rpt",
        grupo: "VENTAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Subdiario de IVA Ventas Por Provincias"
    },
    {
        nombreRPT: "Cry_ReportERP042.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Detalles de Ventas por Vendedor y Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP043.rpt",
        grupo: "VENTAS",
        subgrupo: "Informes Varios",
        reporte: "Reimpresión de Cotización"
    },
    {
        nombreRPT: "Cry_ReportERP044.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Ventas por Categorías y por Clientes"
    },
    {
        nombreRPT: "Cry_ReportERP045.rpt",
        grupo: "VENTAS",
        subgrupo: "Promociones y fidelización",
        reporte: "Lista de Puntos de Promociones por Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP046.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Lista de Ventas por Proveedor"
    },
    {
        nombreRPT: "Cry_ReportERP047.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumen de Ventas",
        reporte: "Lista de Ventas por Sucursal y Unidades"
    },
    {
        nombreRPT: "Cry_ReportERP048.rpt",
        grupo: "VENTAS",
        subgrupo: "Cálculos de Comisiones",
        reporte: "Comisiones Según Artículos por Vendedor"
    },
    {
        nombreRPT: "Cry_ReportERP049.rpt",
        grupo: "VENTAS",
        subgrupo: "Cálculos de Comisiones",
        reporte: "Comisiones por Vendedor sobre Artículos"
    },
    {
        nombreRPT: "Cry_ReportERP049B.rpt",
        grupo: "VENTAS",
        subgrupo: "Cálculos de Comisiones",
        reporte: "Comisiones por Vendedor sobre Artículos (Sin Agrupar)"
    },
    {
        nombreRPT: "Cry_ReportERP050.rpt",
        grupo: "VENTAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Subdiario de IVA Ventas con Detalles de IVA"
    },
    {
        nombreRPT: "Cry_ReportERP050B.rpt",
        grupo: "VENTAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Subdiario de IVA Ventas con Detalles de IVA desde Comprobantes"
    },
    {
        nombreRPT: "Cry_ReportERP052.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Detalles de Comprobantes Anulados"
    },
    {
        nombreRPT: "Cry_ReportERP053.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Clientes",
        reporte: "Comision por Factura Cobrada"
    },
    {
        nombreRPT: "Cry_ReportERP054.rpt",
        grupo: "VENTAS",
        subgrupo: "Cálculos de Comisiones",
        reporte: "Lista de Comisiones por Lista"
    },
    {
        nombreRPT: "Cry_ReportERP055.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Facturas Pendientes de Acopio por Importe"
    },
    {
        nombreRPT: "Cry_ReportERP056.rpt",
        grupo: "VENTAS",
        subgrupo: "Pedidos - Remitos - Cotizaciones",
        reporte: "Lista de Pendientes de Acopio por Artículo"
    },
    {
        nombreRPT: "Cry_ReportERP058.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumen de Ventas",
        reporte: "Lista de Ventas de Precursores Químicos"
    }, {
        nombreRPT: "Cry_ReportERP059.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumen de Ventas",
        reporte: "Lista de Ventas de Combos de Artículos"
    },
    {
        nombreRPT: "Cry_ReportERP060.rpt",
        grupo: "VENTAS",
        subgrupo: "Prefacturas",
        reporte: "Historial de Prefacturas"
    },
    {
        nombreRPT: "Cry_ReportERP061.rpt",
        grupo: "VENTAS",
        subgrupo: "Pedidos - Remitos - Cotizaciones",
        reporte: "Lista de Pedidos por Cliente - E-COMMERCE"
    },
    {
        nombreRPT: "Cry_ReportERP062.rpt",
        grupo: "VENTAS",
        subgrupo: "Rankings de Ventas",
        reporte: "Ranking de Utilidad por Articulo"
    },
    {
        nombreRPT: "Cry_ReportERP103.rpt",
        grupo: "COMPRAS",
        subgrupo: "Resumenes de Compras",
        reporte: "Resumen de Compras por Concepto"
    },
    {
        nombreRPT: "Cry_ReportERP104.rpt",
        grupo: "COMPRAS",
        subgrupo: "Resumenes de Compras",
        reporte: "Resumen de Compras por Condición de Compra"
    },
    {
        nombreRPT: "Cry_ReportERP105.rpt",
        grupo: "COMPRAS",
        subgrupo: "Rankings de Compras",
        reporte: "Ranking de Compras por Proveedor"
    },
    {
        nombreRPT: "Cry_ReportERP106.rpt",
        grupo: "COMPRAS",
        subgrupo: "Rankings de Compras",
        reporte: "Ranking de Compras por Artículo"
    },
    {
        nombreRPT: "Cry_ReportERP107.rpt",
        grupo: "COMPRAS",
        subgrupo: "Rankings de Compras",
        reporte: "Ranking de Compras por Proveedor/Artículo"
    },
    {
        nombreRPT: "Cry_ReportERP108.rpt",
        grupo: "COMPRAS",
        subgrupo: "Rankings de Compras",
        reporte: "Ranking de Compras por Artículo/Proveedor"
    },
    {
        nombreRPT: "Cry_ReportERP109E.rpt",
        grupo: "COMPRAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Subdiario de IVA Compras"
    },
    {
        nombreRPT: "Cry_ReportERP109F.rpt",
        grupo: "COMPRAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Subdiario de IVA Compras (Fecha Fiscal)"
    },
    {
        nombreRPT: "Cry_ReportERP109G.rpt",
        grupo: "COMPRAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Subdiario de IVA Compras (Fecha Fiscal)"
    },
    {
        nombreRPT: "Cry_ReportERP109L.rpt",
        grupo: "COMPRAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Subdiario de IVA Compras (Fecha Fiscal)"
    },
    {
        nombreRPT: "Cry_ReportERP110.rpt",
        grupo: "COMPRAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Lista de Comprobantes de Compra por Tipo"
    },
    {
        nombreRPT: "Cry_ReportERP111.rpt",
        grupo: "COMPRAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Lista de Comprobantes de Compra por Proveedor"
    },
    {
        nombreRPT: "Cry_ReportERP112.rpt",
        grupo: "COMPRAS",
        subgrupo: "Pedidos - Remitos - Cotizaciones",
        reporte: "Lista de Pendientes por Artículo"
    },
    {
        nombreRPT: "Cry_ReportERP114.rpt",
        grupo: "COMPRAS",
        subgrupo: "Pedidos - Remitos - Cotizaciones",
        reporte: "Lista de Ordenes de Compra por Proveedor"
    },
    {
        nombreRPT: "Cry_ReportERP114O.rpt",
        grupo: "COMPRAS",
        subgrupo: "Pedidos - Remitos - Cotizaciones",
        reporte: "Listado de Ordenes de Compra por Proveedor"
    },
    {
        nombreRPT: "Cry_ReportERP115.rpt",
        grupo: "COMPRAS",
        subgrupo: "Históricos de Costos",
        reporte: "Histórico de Costos por Proveedor"
    },
    {
        nombreRPT: "Cry_ReportERP116.rpt",
        grupo: "COMPRAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Subdiario de IVA Compras (Discriminado)"
    },
    {
        nombreRPT: "Cry_ReportERP117.rpt",
        grupo: "COMPRAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Subdiario de IVA Compras Por Provincias"
    },
    {
        nombreRPT: "Cry_ReportERP118.rpt",
        grupo: "COMPRAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Subdiario de IVA Compras con Detalles de IVA"
    },
    {
        nombreRPT: "Cry_ReportERP119.rpt",
        grupo: "COMPRAS",
        subgrupo: "Subdiarios - Asientos - Comprobantes",
        reporte: "Subdiario de IVA Compras por Tipos de Proveedor"
    },
    {
        nombreRPT: "Cry_ReportERP120.rpt",
        grupo: "COMPRAS",
        subgrupo: "Resumenes de Compras",
        reporte: "Resumen de Compras por Concepto de Compra"
    },
    {
        nombreRPT: "Cry_ReportERP123.rpt",
        grupo: "COMPRAS",
        subgrupo: "Históricos de Costos",
        reporte: "Comparación de Listas de Precios de Proveedores"
    },
    {
        nombreRPT: "Cry_ReportERP201.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Clientes",
        reporte: "Resumen de Cuenta de Clientes"
    },
    {
        nombreRPT: "Cry_ReportERP206O.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Proveedores",
        reporte: "Deudas Vencidas por Proveedor"
    },
    {
        nombreRPT: "Cry_ReportERP208.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Proveedores",
        reporte: "Deudas Vencidas por Fecha"
    },
    {
        nombreRPT: "Cry_ReportERP210.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Proveedores",
        reporte: "Lista de Saldos de Proveedores"
    },
    {
        nombreRPT: "Cry_ReportERP212B.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Proveedores",
        reporte: "Listado de Ordenes de Pago Emitidas"
    },
    {
        nombreRPT: "Cry_ReportERP213.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Clientes",
        reporte: "Resumen de Cuenta de Clientes por Vendedor"
    },
    {
        nombreRPT: "Cry_ReportERP214.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Proveedores",
        reporte: "Listado de Saldos de Proveedores A Fecha"
    },
    {
        nombreRPT: "Cry_ReportERP214B.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Proveedores",
        reporte: "Listado de Saldos de Proveedores A Fecha"
    },
    {
        nombreRPT: "Cry_ReportERP214C.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Proveedores",
        reporte: "Lista de Saldos de Proveedores A Fecha Con Filtro Sucursal Multiple"
    },
    {
        nombreRPT: "Cry_ReportERP216.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Clientes",
        reporte: "Lista de Saldos de Clientes A Fecha"
    },
    {
        nombreRPT: "Cry_ReportERP217.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Clientes",
        reporte: "Saldo por Cliente entre Períodos"
    },
    {
        nombreRPT: "Cry_ReportERP220I.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Proveedores",
        reporte: "Resumen de Saldos por Proveedor"
    },
    {
        nombreRPT: "Cry_ReportERP221.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Proveedores",
        reporte: "Saldo por Proveedor entre Períodos"
    },
    {
        nombreRPT: "Cry_ReportERP222.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Proveedores",
        reporte: "Lista de Composición de Saldos de Proveedores A Fecha"
    },
    {
        nombreRPT: "Cry_ReportERP223.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Clientes",
        reporte: "Lista de Composición de Saldos de Clientes A Fecha"
    },
    {
        nombreRPT: "Cry_ReportERP224.rpt",
        grupo: "CUENTAS CORRIENTES",
        subgrupo: "Cuentas Corrientes de Clientes",
        reporte: "Lista de Saldos Actuales de Clientes"
    },
    {
        nombreRPT: "Cry_ReportERP301.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Listado de Stock por Depósito"
    },
    {
        nombreRPT: "Cry_ReportERP301F.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Lista de Stock por Depósito con Número de Series"
    },
    {
        nombreRPT: "Cry_ReportERP304.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Lista de Stock por Grupo"
    },
    {
        nombreRPT: "Cry_ReportERP305.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Lista de Stock por Marca"
    },
    {
        nombreRPT: "Cry_ReportERP306B.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Listado de Stock por Proveedor (TD)"
    },
    {
        nombreRPT: "Cry_ReportERP307.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Lista de Stock Comparativo entre Depósitos"
    },
    {
        nombreRPT: "Cry_ReportERP308.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Lista de Stock Proyectado"
    },
    {
        nombreRPT: "Cry_ReportERP309.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Valorizaciones",
        reporte: "Valorización de Stock"
    },
    {
        nombreRPT: "Cry_ReportERP310.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Valorizaciones",
        reporte: "Valorización de Stock por Depósito"
    },
    {
        nombreRPT: "Cry_ReportERP311.rpt",
        grupo: "STOCK",
        subgrupo: "Alertas de Stock",
        reporte: "Artículos Bajo Stock Mínimo"
    },
    {
        nombreRPT: "Cry_ReportERP312.rpt",
        grupo: "STOCK",
        subgrupo: "Alertas de Stock",
        reporte: "Artículos Bajo Punto de Pedido"
    },
    {
        nombreRPT: "Cry_ReportERP315.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Lista de Transferencias entre Depósitos"
    },
    {
        nombreRPT: "Cry_ReportERP316.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Valorizaciones",
        reporte: "Valorización de Stock por Depósito Filtros Varios"
    },
    {
        nombreRPT: "Cry_ReportERP317.rpt",
        grupo: "STOCK",
        subgrupo: "Movimientos de Stock",
        reporte: "Movimientos de Stock por Grupos y Proveedores, en Litros"
    },
    {
        nombreRPT: "Cry_ReportERP318.rpt",
        grupo: "STOCK",
        subgrupo: "Movimientos de Stock",
        reporte: "Movimientos de Stock por Grupos y Proveedores con Drill Down, en Litros"
    },
    {
        nombreRPT: "Cry_ReportERP319.rpt",
        grupo: "STOCK",
        subgrupo: "Saldos de Stock",
        reporte: "Lista Informe Sedronar por Comprobantes"
    },
    {
        nombreRPT: "Cry_ReportERP320.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Lista de Stock por Familia, Grupo y Marca"
    },
    {
        nombreRPT: "Cry_ReportERP321.rpt",
        grupo: "STOCK",
        subgrupo: "Alertas de Stock",
        reporte: "Artículos Bajo Stock Mínimo (Por Depósito)"
    },
    {
        nombreRPT: "Cry_ReportERP322.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Ajustes de Stock por Concepto"
    },

    {
        nombreRPT: "Cry_ReportERP323.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Ajustes de Stock"
    },

    {
        nombreRPT: "Cry_ReportERP327.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Ficha de Movimientos de Stock por Deposito"
    },

    {
        nombreRPT: "Cry_ReportERP328.rpt",
        grupo: "STOCK",
        subgrupo: "Saldos de Stock",
        reporte: "Lista Informe Sedronar por Movimientos"
    },

    {
        nombreRPT: "Cry_ReportERP328B.rpt",
        grupo: "STOCK",
        subgrupo: "Saldos de Stock",
        reporte: "Lista Informe Sedronar - Planilla Trimestral"
    },

    {
        nombreRPT: "Cry_ReportERP329.rpt",
        grupo: "STOCK",
        subgrupo: "Listados de Stock",
        reporte: "Lista de Reposición de Sucursales"
    },

    {
        nombreRPT: "Cry_ReportERP401.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Artículos",
        reporte: "Catálogo de Artículos"
    },

    {
        nombreRPT: "Cry_ReportERP404.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Artículos",
        reporte: "Catálogo de Artículos por Familia, Grupo y Marca"
    },

    {
        nombreRPT: "Cry_ReportERP406.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Artículos",
        reporte: "Catálogo de Artículos por Proveedor y Marca"
    },

    {
        nombreRPT: "Cry_ReportERP407.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Precios y Costos",
        reporte: "Lista de Precios"
    },

    {
        nombreRPT: "Cry_ReportERP409.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Precios y Costos",
        reporte: "Lista de Precios por Familia y Grupo"
    },

    {
        nombreRPT: "Cry_ReportERP410.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Precios y Costos",
        reporte: "Lista de Precios por Familia, Grupo y Marca"
    },

    {
        nombreRPT: "Cry_ReportERP412.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Precios y Costos",
        reporte: "Lista de Precios por Proveedor y Marca"
    },

    {
        nombreRPT: "Cry_ReportERP413.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listado de Artículos",
        reporte: "Lista de Artículos Nuevos y Modificados"
    },

    {
        nombreRPT: "Cry_ReportERP414.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Artículos",
        reporte: "Lista de Control de Formación de Costos y Precios"
    },
    {
        nombreRPT: "Cry_ReportERP415.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Precios y Costos",
        reporte: "Lista de Precios Resumida"
    },

    {
        nombreRPT: "Cry_ReportERP416.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Artículos",
        reporte: "Lista de Movimientos de Numero de Serie"
    },

    {
        nombreRPT: "Cry_ReportERP417.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Precios y Costos",
        reporte: "Lista de Precios por Categorías"
    },

    {
        nombreRPT: "Cry_ReportERP418.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listado de Artículos",
        reporte: "Lista de Artículos Modificados con Precio Histórico"
    },

    {
        nombreRPT: "Cry_ReportERP419.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Promociones y Fidelización",
        reporte: "Catálogo de Artículos Canjeables"
    },
    {
        nombreRPT: "Cry_ReportERP422.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Artículos",
        reporte: "Lista de Artículos con Stock Mínimo"
    },
    {
        nombreRPT: "Cry_ReportERP423.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Precios y Costos",
        reporte: "Lista de Precio Promedio Ponderado"
    },
    {
        nombreRPT: "Cry_ReportERP424.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Artículos",
        reporte: "Catálogo de Artículos (Multiempresa)"
    },
    {
        nombreRPT: "Cry_ReportERP425.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Artículos",
        reporte: "Lista de Lotes por Artículos"
    },
    {
        nombreRPT: "Cry_ReportERP426.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Artículos",
        reporte: "Lista de Artículos con Stock Máximo Y Mínimo"
    },
    {
        nombreRPT: "Cry_ReportERP427.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Precios y Costos",
        reporte: "Lista de Rentabilidad de Productos por Proveedor"
    },
    {
        nombreRPT: "Cry_ReportERP428.rpt",
        grupo: "ARTICULOS",
        subgrupo: "Listados de Precios y Costos",
        reporte: "Lista de Modificación de Precios"
    },
    {
        nombreRPT: "Cry_ReportERP502.rpt",
        grupo: "CLIENTES",
        subgrupo: "Nómina de Clientes",
        reporte: "Lista de Clientes por Vendedor"
    },
    {
        nombreRPT: "Cry_ReportERP503.rpt",
        grupo: "CLIENTES",
        subgrupo: "Nómina de Clientes",
        reporte: "Lista de Clientes por Zona"
    },
    {
        nombreRPT: "Cry_ReportERP505.rpt",
        grupo: "CLIENTES",
        subgrupo: "Nómina de Clientes",
        reporte: "Lista de Clientes por Localidad"
    },
    {
        nombreRPT: "Cry_ReportERP506.rpt",
        grupo: "CLIENTES",
        subgrupo: "Nómina de Clientes",
        reporte: "Lista de Clientes por Provincia y Localidad"
    },
    {
        nombreRPT: "Cry_ReportERP507.rpt",
        grupo: "CLIENTES",
        subgrupo: "Nómina de Clientes",
        reporte: "Lista de Limites de Credito"
    },
    {
        nombreRPT: "Cry_ReportERP508.rpt",
        grupo: "CLIENTES",
        subgrupo: "Nómina de Clientes",
        reporte: "Lista de Clientes por Tipo IVA"
    },
    {
        nombreRPT: "Cry_ReportERP512.rpt",
        grupo: "VARIOS",
        subgrupo: "Listados de Usuarios",
        reporte: "Lista de Movimientos de Usuarios"
    },
    {
        nombreRPT: "Cry_ReportERP513.rpt",
        grupo: "CLIENTES",
        subgrupo: "Nómina de Clientes",
        reporte: "Lista de Clientes por Ramo"
    },
    {
        nombreRPT: "Cry_ReportERP514.rpt",
        grupo: "CLIENTES",
        subgrupo: "Nómina de Clientes",
        reporte: "Lista de Clientes (Multiempresa)"
    },
    {
        nombreRPT: "Cry_ReportERP602.rpt",
        grupo: "PROVEEDORES",
        subgrupo: "Nómina de Proveedores",
        reporte: "Lista de Proveedores por Categoría"
    },
    {
        nombreRPT: "Cry_ReportERP603.rpt",
        grupo: "PROVEEDORES",
        subgrupo: "Nómina de Proveedores",
        reporte: "Lista de Proveedores por Tipo IVA"
    },
    {
        nombreRPT: "Cry_ReportERP604.rpt",
        grupo: "PROVEEDORES",
        subgrupo: "Nómina de Proveedores",
        reporte: "Lista de Proveedores (Multiempresa)"
    },
    {
        nombreRPT: "Cry_ReportERP702.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cheques",
        reporte: "Lista de Cheques en Cartera por Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP703.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cheques",
        reporte: "Lista Histórico de Cheques"
    },
    {
        nombreRPT: "Cry_ReportERP704.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cheques",
        reporte: "Lista Histórico de Cheques por Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP705.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cheques",
        reporte: "Lista Histórico de Cheques por Estado"
    },
    {
        nombreRPT: "Cry_ReportERP706.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cheques",
        reporte: "Lista Histórico de Cheques por Estado \\ Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP707.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cheques",
        reporte: "Lista Histórico de Cheques por Cliente \\ Estado"
    },
    {
        nombreRPT: "Cry_ReportERP708.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cheques",
        reporte: "Lista de Cheques Entregados a Proveedores"
    },
    {
        nombreRPT: "Cry_ReportERP710.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cupones de Tarjetas",
        reporte: "Lista de Cupones en Cartera por Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP711.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cupones de Tarjetas",
        reporte: "Lista de Cupones en Cartera por Tarjeta"
    },
    {
        nombreRPT: "Cry_ReportERP712.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cupones de Tarjetas",
        reporte: "Lista Histórico de Cupones"
    },
    {
        nombreRPT: "Cry_ReportERP713.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cupones de Tarjetas",
        reporte: "Lista Histórico de Cupones por Cliente"
    },
    {
        nombreRPT: "Cry_ReportERP714.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cupones de Tarjetas",
        reporte: "Lista Histórico de Cupones por Estado"
    },
    {
        nombreRPT: "Cry_ReportERP715.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cupones de Tarjetas",
        reporte: "Lista Histórico de Cupones por Tarjeta"
    },
    {
        nombreRPT: "Cry_ReportERP717B.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Fondos Generales",
        reporte: "Listado de Movimientos de Fondos por Tipo"
    },
    {
        nombreRPT: "Cry_ReportERP719.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cheques",
        reporte: "Lista de Cheques en Cartera por Fecha de Ingreso"
    },
    {
        nombreRPT: "Cry_ReportERP720.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cheques",
        reporte: "Lista de Cheques con Trazabilidad"
    },
    {
        nombreRPT: "Cry_ReportERP721.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cupones de Tarjetas",
        reporte: "Lista de Tarjetas por Lote"
    },
    {
        nombreRPT: "Cry_ReportERP723.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Fondos Generales",
        reporte: "Lista de Caja por Usuario"
    },
    {
        nombreRPT: "Cry_ReportERP724.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Fondos Generales",
        reporte: "Lista de Caja por Sucursal y por Puntos de Venta"
    },
    {
        nombreRPT: "Cry_ReportERP725.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cheques",
        reporte: "Lista de Cheques Generalizado"
    },
    {
        nombreRPT: "Cry_ReportERP726.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cupones de Tarjetas",
        reporte: "Lista de Cupones Generalizado"
    },
    {
        nombreRPT: "Cry_ReportERP728.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Fondos Generales",
        reporte: "Lista de Movimientos de Fondos por Concepto"
    },
    {
        nombreRPT: "Cry_ReportERP729.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cupones de Tarjetas",
        reporte: "Listado de Cupones en Cartera a Fecha"
    },
    {
        nombreRPT: "Cry_ReportERP729G.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cupones de Tarjetas",
        reporte: "Lista de Cupones en Cartera a Fecha"
    },

    {
        nombreRPT: "Cry_ReportERP730.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Fondos Generales",
        reporte: "Reimpresión de Cierres y Aperturas de Caja"
    },

    {
        nombreRPT: "Cry_ReportERP731.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Fondos Generales",
        reporte: "Lista de Movimientos de Cambios de Valores"
    },

    {
        nombreRPT: "Cry_ReportERP732.rpt",
        grupo: "FONDOS",
        subgrupo: "Listado de cotizaciones de monedas",
        reporte: "Lista de Cotizaciones de Monedas"
    },

    {
        nombreRPT: "Cry_ReportERP733.rpt",
        grupo: "FONDOS",
        subgrupo: "Listados de Cheques",
        reporte: "Análisis Gráfico"
    },

    {
        nombreRPT: "Cry_ReportERP802.rpt",
        grupo: "BANCOS",
        subgrupo: "Movimientos Bancarios",
        reporte: "Lista de Movimientos Bancarios por Tipo por Banco"
    },

    {
        nombreRPT: "Cry_ReportERP802B.rpt",
        grupo: "BANCOS",
        subgrupo: "Movimientos Bancarios",
        reporte: "Lista de Movimientos Bancarios por Tipo por Cuenta"
    },

    {
        nombreRPT: "Cry_ReportERP803.rpt",
        grupo: "BANCOS",
        subgrupo: "Movimientos Bancarios",
        reporte: "Saldos de Cuentas Bancarias"
    },

    {
        nombreRPT: "Cry_ReportERP804.rpt",
        grupo: "BANCOS",
        subgrupo: "Movimientos Bancarios",
        reporte: "Lista de Cheques Propios entre Fechas"
    },

    {
        nombreRPT: "Cry_ReportERP805.rpt",
        grupo: "BANCOS",
        subgrupo: "Movimientos Bancarios",
        reporte: "Lista de Movimientos Bancarios por Conceptos"
    },

    {
        nombreRPT: "Cry_ReportERP806.rpt",
        grupo: "BANCOS",
        subgrupo: "Movimientos Bancarios",
        reporte: "Conciliaciones Bancarias"
    },

    {
        nombreRPT: "Cry_ReportERP807.rpt",
        grupo: "BANCOS",
        subgrupo: "Movimientos Bancarios",
        reporte: "Lista de Conciliación Bancaria y Mayor Contable"
    },

    {
        nombreRPT: "Cry_ReportERP808.rpt",
        grupo: "BANCOS",
        subgrupo: "Movimientos Bancarios",
        reporte: "Lista de Movimientos de Conciliación Bancaria"
    },

    {
        nombreRPT: "Cry_ReportERP902C.rpt",
        grupo: "CONTABLE",
        subgrupo: "Listados Contables Subdiarios",
        reporte: "Mayor de Cuentas (Subdiario) - Contabilidad 2.0"
    }, {
        nombreRPT: "Cry_ReportERP903.rpt",
        grupo: "CONTABLE",
        subgrupo: "Listados Contables Diarios",
        reporte: "Asientos Contables (Diario)"
    },

    {
        nombreRPT: "Cry_ReportERP904C.rpt",
        grupo: "CONTABLE",
        subgrupo: "Listados Contables Diarios",
        reporte: "Mayor De Cuentas Del Subdiario Agrupados Por Diario - Contabilidad 2.0"
    },

    {
        nombreRPT: "Cry_ReportERP905BC.rpt",
        grupo: "CONTABLE",
        subgrupo: "Balances Generales",
        reporte: "Balance de Sumas y Saldos Ordenado - Contabilidad 2.0"
    },

    {
        nombreRPT: "Cry_ReportERP905C.rpt",
        grupo: "CONTABLE",
        subgrupo: "Balances Generales",
        reporte: "Balance de Sumas y Saldos"
    },
    {
        nombreRPT: "Cry_ReportERP907C.rpt",
        grupo: "CONTABLE",
        subgrupo: "Balances Generales",
        reporte: "Detalle del Plan de Cuentas"
    },
    {
        nombreRPT: "Cry_ReportERP908C.rpt",
        grupo: "CONTABLE",
        subgrupo: "Balances Generales",
        reporte: "Control Prorrateado del Ejercicio"
    },
    {
        nombreRPT: "Cry_ReportERP909B.rpt",
        grupo: "CONTABLE",
        subgrupo: "Retenciones y Percepciones",
        reporte: "Lista General de Ret.Per. - Fecha de Retencion"
    },
    {
        nombreRPT: "Cry_ReportERP909G.rpt",
        grupo: "CONTABLE",
        subgrupo: "Retenciones y Percepciones",
        reporte: "Lista General de Ret.Per. - Origen Multiple"
    },
    {
        nombreRPT: "Cry_ReportERP910C.rpt",
        grupo: "CONTABLE",
        subgrupo: "Listados Contables Subdiarios",
        reporte: "Mayor de Cuentas (Subdiario) + Origen"
    },
    {
        nombreRPT: "Cry_ReportERP913.rpt",
        grupo: "CONTABLE",
        subgrupo: "Balances Generales",
        reporte: "Lista de Movimientos de Cuentas Por Centro de Costo"
    },
    {
        nombreRPT: "Cry_ReportERPCRM.rpt",
        grupo: "VENTAS",
        subgrupo: "Resumenes de Ventas",
        reporte: "Lista de Pack de Artículos"
    }
];

export default expressReports;