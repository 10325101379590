import React from 'react';
import {
    Dialog,
    Button,
    DialogContent,
    Typography
} from "@material-ui/core";
import { I18N } from "../../i18n/I18N";
import { CustomDialogStyle } from "./CustomDialogStyle";
import { CustomDialogProps } from './CustomDialogProps';


export function CustomDialog(customDialogProps: CustomDialogProps) {

    const classes = CustomDialogStyle();

    return (
        <Dialog open={customDialogProps.open} disableEscapeKeyDown >
            <DialogContent>
                <div className="processing" >
                    <img className="warning" src={require("../../images/warning.png")} alt="warning" />
                </div>
                <span className="dialog-error">
                    <Typography align="center" variant="h6">
                        {customDialogProps.textPrimary}
                    </Typography>
                    <Typography color="textSecondary" variant="h3">
                        {customDialogProps.textSecondary}
                    </Typography>
                </span>
                <div className="footer">
                    <Button
                        className={classes.flatButton}
                        onClick={customDialogProps.onClick}
                    >
                        {I18N.get("txtAccept")}
                    </Button>
                </div>
            </DialogContent>
        </Dialog >

    )

}