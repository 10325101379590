import { createMuiTheme } from "@material-ui/core/styles";

export const textfieldTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#4285f4"
    },
    text: {
      primary: "#263238"
    }
  },
  typography: {
    fontSize: 12.25,
    fontFamily: "'Ubuntu', sans-serif",
    h3: {
      fontSize: 16,
      fontWeight: 300
    },
    h4: {
      fontSize: 20,
      fontWeight: 300
    },
    h6: {
      fontSize: 24,
      fontWeight: 300
    },
    subtitle1: {
      fontSize: 24,
      fontWeight: 300,
      color: "rgba(38, 50, 56, 0.7)"
    }
  }
});
