import "./WizardLayout.scss";

import React, { Component } from "react";
import WizardForm from "../WizardForm/WizardForm";

export class WizardLayout extends Component<any, any> {

  constructor(props: any) {
    super(props);

    this.state = {
      version: ""
    }
  }

  render() {
    return (
      <div className="wizard-layout">
        <header>
          <img className="logo" src={require("../../images/logo.svg")} alt="logo" />
        </header>
        <div>
          <img className='person' src={require("../../images/person.svg")} alt="person" />
          <WizardForm />
        </div>
      </div>
    );
  }

}

export default WizardLayout;
