import { LanguagesProperties } from "../LanguagesProperties";

export const en: LanguagesProperties = {
  wizardFormTitle: "Complete the remaining fields to create an user.",
  requiredField: "Required field",
  invalidMailFormat: "Invalid mail format",
  invalidIdentifier: "Invalid identifier",
  min6charsField: "The field must have at least 6 characters",
  alphanumeric: "The field must contain at least one letter and one number",
  passwordsDontMatch: "The passwords must match",
  requiredCaptcha: "The captcha is required",
  txtEmail: "Jira Mail",
  txtPassword: "Password",
  txtRepeatPassword: "Repeat password",
  txtIdentification: "SSN",
  txtRegister: "Register",
  txtAccept: "Accept",
  txtCompanyName: "Company Name",
  txtCustomerCode: "Customer Code",
  txtAdditionalReports: "Additional Reports",
  btnAdditionalReports: "Additional Reports",
  btnAcceptModalReportsExpress: "Accept",
  btnCancelModalReportsExpress: "Cancel",
  txtSearchReports: "Search"
};
